@use './mixins';
@use './variables' as *;

// @font-face here; preload fonts at _document.jsx
// Source: https://app.asana.com/0/search/1200409423756339/1200214951381569
@import url('//fast.fonts.net/t/1.css?apiType=css&projectid=d741f0b3-318e-41b0-a5e8-47a26cbc7a79');

// Penumbra Flare Std Bold'
@font-face {
  font-display: swap;
  font-family: 'Penumbra Flare Std Bold';
  src: url('/fonts/PenumbraFlareStd-Bold.woff2') format('woff2'),
    url('/fonts/PenumbraFlareStd-Bold.woff') format('woff');
}

// Future PT W02 Book
@font-face {
  font-display: swap;
  font-family: 'Futura PT Book';
  src: url('/fonts/cb9d11fa-bd41-4bd9-8b8f-34ccfc8a80a2.woff2') format('woff2'),
    url('/fonts/475da8bf-b453-41ee-ab0e-bd9cb250e218.woff') format('woff');
}

// Future PT W02 Medium
@font-face {
  font-display: swap;
  font-family: 'Futura PT';
  src: url('/fonts/91597685-99ad-4a3c-81b0-84491562b714.woff2') format('woff2'),
    url('/fonts/717bc962-d7ad-40ef-b37b-808824c7f061.woff') format('woff');
  // font-weight: bold;
}

// Future PT W10 Book (Cyrillic)
// @font-face {
//   font-family: 'Futura PT';
//   src: url('/fonts/0ee022b1-6a5b-4b40-9569-a3f3ada29ba6.woff2') format('woff2'),
//     url('/fonts/ee16f59d-9719-44bb-8ebf-98009d414cb7.woff') format('woff');
//   unicode-range: U+370-FFFF;
// }

// Future PT W10 Medium (Cyrillic)
@font-face {
  font-display: swap;
  font-family: 'Futura PT';
  // font-weight: bold;
  src: url('/fonts/49d7914b-a2b8-4a34-9570-6d3ebdeb244d.woff2') format('woff2'),
    url('/fonts/d39c7401-d921-4ce4-9ff3-e7069613afe0.woff') format('woff');
  unicode-range: U+370-FFFF;
}

// Source: https://brandportal.keune.com/web/59f31a569bba9151/keune-corporate-fonts/
@font-face {
  font-display: swap;
  font-family: 'Benton';
  src: url('/fonts/BentonModernOne.otf');
}

@font-face {
  font-display: swap;
  font-family: 'Benton';
  src: url('/fonts/BentonModTitl-Regular.otf');
  font-weight: bold;
}
@font-face {
  font-display: swap;
  font-family: 'Benton';
  src: url('/fonts/BentonModernOne-Italic.otf');
  font-style: italic, oblique;
}
@font-face {
  font-display: swap;
  font-family: 'Benton';
  src: url('/fonts/BentonModTitl-RegularItalic.otf');
  font-weight: bold;
  font-style: italic, oblique;
}

// Acumin fonts
@font-face {
  font-family: 'Acumin Pro';
  src: url('/fonts/AcuminPro-BoldItalic.woff2') format('woff2'),
    url('/fonts/AcuminPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('/fonts/AcuminPro-Italic.woff2') format('woff2'),
    url('/fonts/AcuminPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('/fonts/AcuminPro-Bold.woff2') format('woff2'),
    url('/fonts/AcuminPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Acumin Pro';
  src: url('/fonts/AcuminPro-Regular.woff2') format('woff2'),
    url('/fonts/AcuminPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: 'Freight Big';
  src: url('/fonts/freigbigcmpprosemi.otf');
}

html {
  scroll-behavior: smooth;
  // 1 rem = 10px with the following dimensions
  font-size: calc(10 / 375 * 100vw); // iPhone 6/7/8

  @include mixins.media {
    font-size: calc(10 / 1440 * 100vw);
  }
}

body {
  font-family: $font-body, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  background-color: $keune-background;

  // for development grid
  &::before {
    content: '';
  }

  :target {
    scroll-margin-top: 10rem;
  }
}

a,
button {
  cursor: pointer;
  outline: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
}

input {
  outline: none;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h1 {
  font-family: $font-title;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: $font-headline;
}

// WAE rating styles used in Page Product
.wae-embed-frame .embed-container {
  height: auto !important;
  .header-rating-average,
  .header-rating-number,
  .header-user-info-name,
  .post-content-title,
  .post-content-text-body,
  .header-user-time,
  .read-more,
  .posts-load-more {
    font-family: $font-body !important;
    letter-spacing: -0.05rem !important;
  }
  .header-user-time {
    height: auto !important;
    line-height: 1.4rem !important;
    font-size: 1rem !important;
    padding: 0.2rem 0;
    @include mixins.media {
      line-height: 1.2rem !important;
      font-size: 1.2rem !important;
    }
  }
  .header-sorting .sorting select {
    border-color: $keune-lightgrey;
    color: $keune-black;
    font-family: $font-headline !important;
    font-size: 1.6rem !important;
    padding: 0 0.5rem !important;
    height: 3rem !important;
  }
  .post-header-user,
  .header-user-info-name {
    height: auto !important;
    padding-bottom: 0.2rem !important;
  }
  .header-user-info-name,
  .post-content-text-body {
    font-size: 1.6rem !important;
    @include mixins.media {
      font-size: 1.6rem !important;
    }
  }
  .header-user-avatar {
    width: 6rem;
    min-width: 6rem;
  }
  .header-user-info-name {
    margin-bottom: 0 !important;
  }
  .post-content-title {
    padding-top: 0 !important;
    margin-bottom: 0.5rem !important;
    font-size: 1.5rem !important;
    letter-spacing: -0.05rem !important;
  }
  .posts-load-more {
    width: 25rem;
    background: $keune-black;
    color: #ffffff;
    border-radius: $button-border-radius;
    font-size: 1.4rem;
    margin-left: 0.7rem;
    border-color: $keune-black;
    font-weight: 400;
    &:hover {
      color: #ffffff;
      background: $keune-black;
    }
    margin: auto;
    margin-top: 2rem;
  }
  .read-more {
    text-decoration: underline;
    color: $keune-black !important;
  }
  .post-content-image,
  .post-content-image-before-after {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $keune-black !important;
  }
  ::-webkit-scrollbar-track {
    background-color: $keune-lightgrey !important;
  }
  .header-rating-average,
  .header-rating-number {
    font-size: 1.6rem;
    margin-top: 0.2rem !important;
  }
  .header-rating-average {
    margin-right: 0.2rem !important;
  }
  .header-rating-number {
    margin-left: 0.2rem !important;
  }
  .rating-star {
    @include mixins.reviewStarsSizes;
    span {
      height: $reviewStarsHeightMobile;
      @include mixins.media {
        height: $reviewStarsHeight;
      }
      &:before {
        @include mixins.reviewStarsSizes;
        @include mixins.reviewBackgroundSizes;
      }
    }
    &:before {
      @include mixins.reviewStarsSizes;
      @include mixins.reviewBackgroundSizes;
    }
  }
}

.wae-rating {
  width: auto;
  display: inline-block;
  .wae-embed-frame {
    .header-rating {
      padding: 1rem 0;
    }
    .header-left {
      margin: 0 !important;
    }
  }
}

#wae-sdk {
  box-shadow: none !important;
  height: auto !important;
}
//end WAE rating styles used in Page Product
